import React, { useContext, useState } from "react";
import axios from "axios";
import { BASE_API_URL, CUST_API_URL } from "../../constants/data";
import { AppContext } from "../../context/AppContext";

const SendOtp = ({ onSuccess, onClose }) => {
  const { setDataForUser, setLoadingState, raiseToast } = useContext(AppContext);

  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [error, setError] = useState("");

  const [user, setUser] = useState({
    phoneNumber: "",
    email: "",
    fname: "",
    lname: "",
    phoneVerified: false,
    city: "",
    pincode: "",
  });

  // Handles sending OTP to the user's phone
  const handleSendOtp = async () => {
    if (!phone) {
      setError("Please fill all the fields.");
      return;
    }
    try {
      const response = await axios.post(`${CUST_API_URL}/phone/request-otp/nc`, { phone, countryCode: "+91" });
      if (response.status === 200) {
        setIsOtpSent(true);
        setError("");
      }
    } catch (error) {
      setError("Failed to send OTP. Please try again.");
    }
  };

  // Verifies the entered OTP and fetches/creates user data based on verification
  const handleVerifyOtp = async () => {
    if (!otp) {
      setError("Please enter the OTP.");
      return;
    }
    try {
      const response = await axios.post(`${CUST_API_URL}/phone/verify-otp`, { phone, otp });
      if (response.status === 200) {
        await onVerify();
        setUser({ ...user, phoneVerified: true });
        setError("");
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      setError("Failed to verify OTP. Please try again.");
    }
  };

  // Checks if the customer already exists in the database
  const checkCustomerExists = async () => {
    try {
      const response = await axios.get(`${CUST_API_URL}/custTable/check`, {
        params: { phone, countryCode: "+91" },
      });
      return false;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        return true;
      } else {
        console.error("Error checking customer existence:", error);
        throw new Error("Error verifying customer status. Please try again.");
      }
    }
  };

  // Verifies if a customer exists and logs them in or prompts for registration
  const onVerify = async () => {
    try {
      const isNewCustomer = await checkCustomerExists();
      if (!isNewCustomer) {
        setIsNewUser(true);
        setError("Please register as a customer.");
      } else {
        await fetchCustomerAndLogin(phone);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Fetches customer data if they exist and logs them in
  const fetchCustomerAndLogin = async (phone) => {
    setLoadingState(true);
    try {
      const res = await axios.get(`${CUST_API_URL}/custTable/by/phone`, {
        params: { phone, countryCode: "+91" },
      });

      if (res.status !== 200) {
        raiseToast(res.data.message, "error");
        setLoadingState(false);
        return;
      }

      const data = mapCustomerData(res.data.data);
      setDataForUser(data);
      console.log(localStorage.getItem("user"));

      setLoadingState(false);
      onSuccess();
      onClose();
    } catch (error) {
      raiseToast("Failed to fetch customer data.", "error");
      setLoadingState(false);
    }
  };

  // Maps the fetched customer data to the required structure
  const mapCustomerData = (data) => ({
    Id: data.AccountNum,
    Token: data.Token,
    Email: data.Email,
    Session: data.Session,
    Name: data.Name,
    RecId: data.RecId,
    PCode: data.PCode,
    Phone: data.Phone,
  });

  // Handles input changes dynamically for the user object
  const handleUserChange = (type, value) => {
    setUser((prevUser) => ({ ...prevUser, [type]: value }));
  };

  // Registers a new customer and logs them in
  const registerCustomerAndLogin = async () => {
    setLoadingState(true);
    if (!(await signUpCustomer(user))) {
      setLoadingState(false);
      return;
    }
    
    const userToSend = {
      fname: user.fname,
      lname: user.lname,
      email: user.email,
      phoneVerified: user.phoneVerified,
      phoneNumber: user.phoneNumber,
      pCode: "+91",
      city: user.city,
      pincode: user.pincode
    };

    try {
      const response = await fetch(BASE_API_URL + "/user/india/sign-up", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userToSend),
      }).then((res) => res.json())
        .catch((error) => error.response.json());

      if (response.status !== 200) {
        raiseToast(response.error, "error");
        setLoadingState(false);
        return;
      }

      setLoadingState(false);
      onSuccess();
      onClose();
    } catch (error) {
      raiseToast(error, "error");
    }
  };

  // Signs up the customer by sending user data to the server
  const signUpCustomer = async (user) => {
    const apiData = {
      Name: `${user.fname} ${user.lname}`,
      Phone: phone,
      PCode: "+91",
      City: user.city,
      ZipCode: user.pincode,
      Email: user.email,
    };

    try {
      const response = await axios.post(`${CUST_API_URL}/custTable/signup`, apiData);
      if (response.status !== 200) {
        raiseToast(response.data.message, "error");
        return false;
      }

      const data = mapCustomerData(response.data.data);
      setDataForUser(data);
      return true;
    } catch (error) {
      raiseToast(error.message, "error");
      return false;
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white md:mx-0 mx-4 md:mb-0 mb-36 rounded-lg shadow-lg p-6 max-w-lg w-full">
        {!isNewUser ? (
          !isOtpSent ? (
            <OtpForm phone={phone} setPhone={setPhone} error={error} onSendOtp={handleSendOtp} />
          ) : (
            <VerifyOtpForm phone={phone} otp={otp} setOtp={setOtp} error={error} onVerifyOtp={handleVerifyOtp} />
          )
        ) : (
          <UserForm user={user} handleUserChange={handleUserChange} onRegister={registerCustomerAndLogin} />
        )}
      </div>
    </div>
  );
};

// Separate components for readability
const OtpForm = ({ phone, setPhone, error, onSendOtp }) => (
  <div className="otp-form flex flex-col justify-center items-center">
    <img src={require("../../assets/navlogo.svg").default} alt="" className="w-26 h-12 mb-4" />
    <h2 className="text-xl mb-4">Enter Your Phone</h2>
    <input
      type="text"
      placeholder="Phone"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      className="input input-bordered input-secondary w-full mb-4"
    />
    {error && <p className="text-red-500 mb-4">{error}</p>}
    <button className="btn btn-success w-full" onClick={onSendOtp}>Send OTP</button>
  </div>
);

const VerifyOtpForm = ({ phone, otp, setOtp, error, onVerifyOtp }) => (
  <div className="verify-otp-form flex flex-col justify-center items-center">
     <img src={require("../../assets/navlogo.svg").default} alt="" className="w-26 h-12 mb-4" />
     <h2 className="text-lg text-center mb-4">Enter the OTP sent to <span className="font-semibold">{phone}</span> </h2>
    <input
      type="text"
      placeholder="OTP"
      value={otp}
      onChange={(e) => setOtp(e.target.value)}
      className="input input-bordered input-secondary w-full mb-4"
    />
    {error && <p className="text-red-500 mb-4">{error}</p>}
    <button className="btn btn-success w-full" onClick={onVerifyOtp}>Verify OTP</button>
  </div>
);

const UserForm = ({ user, handleUserChange, onRegister }) => (
  <div className="user-form flex flex-col justify-center items-center">
    <img src={require("../../assets/nav.png")} alt="" className="w-20 h-20 bg-gray-300 rounded-full mb-4" />
    <h2 className="text-2xl font-semibold mb-4">Enter Your Details</h2>
    <input
      type="text"
      placeholder="First Name"
      value={user.fname}
      onChange={(e) => handleUserChange("fname", e.target.value)}
      className="input input-bordered input-secondary w-full mb-4"
    />
    <input
      type="text"
      placeholder="Last Name"
      value={user.lname}
      onChange={(e) => handleUserChange("lname", e.target.value)}
      className="input input-bordered input-secondary w-full mb-4"
    />
    <input
      type="text"
      placeholder="City"
      value={user.city}
      onChange={(e) => handleUserChange("city", e.target.value)}
      className="input input-bordered input-secondary w-full mb-4"
    />
    <input
      type="text"
      placeholder="Pincode"
      value={user.pincode}
      onChange={(e) => handleUserChange("pincode", e.target.value)}
      className="input input-bordered input-secondary w-full mb-4"
    />
    <input
      type="email"
      placeholder="Email"
      value={user.email}
      onChange={(e) => handleUserChange("email", e.target.value)}
      className="input input-bordered input-secondary w-full mb-4"
    />
    <button className="btn btn-success w-full" onClick={onRegister}>Register</button>
  </div>
);

export default SendOtp;
