import React from "react";
import { Link } from "react-router-dom";
import Banner from "../interior/Banner";

const FinalCostModal = ({ totalCost, onClose, details }) => {
    return (
        <div className="fixed  inset-0 flex justify-center items-center md:px-20 px-4 bg-black bg-opacity-50 backdrop-blur-sm z-50">
            {/* Close Button */}
            <div className="absolute right-4 top-4">
                <button
                    onClick={onClose}
                    className="bg-gray-700 text-white py-2 px-4 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                >
                    Close
                </button>
            </div>

            <div className="bg-white  p-8 rounded-lg shadow-xl w-full ">
                {/* Logo */}
                <div className="flex justify-center mb-6">
                    <img
                        src={require("../../assets/navlogo.svg").default}
                        alt="Logo"
                        className="w-26 h-12"
                    />
                </div>

                {/* Title */}
                {/* <h2 className="text-center text-3xl font-bold text-green-700 mb-4">
                    Your Estimated Cost
                </h2> */}

                {/* Total Cost */}
                {/* <div className="text-center mb-6">
                    <p className="text-lg font-medium">
                        <span className="line-through text-red-500">
                            ₹{(totalCost / 0.7).toFixed(2)}
                        </span>
                        <span className="text-green-800 font-semibold ml-2 text-2xl">
                            ₹{totalCost.toFixed(2)}
                        </span>
                        <span className="text-sm text-red-500 ml-2">(30% off)</span>
                    </p>
                </div> */}

                {/* Details Breakdown */}
                <div className="mb-6">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2">Details:</h3>
                    <ul className="bg-green-50 p-4 rounded-md text-gray-700">
                        <li className="flex justify-between py-1 border-b border-gray-300 last:border-none">
                            <span>Size : </span>
                            <span>{details.buildingArea} sq. ft.</span>
                        </li>

                        <li className="flex justify-between py-1 border-b border-gray-300 last:border-none">
                            <span>Floors :</span>
                            <span>{details.floors}</span>
                        </li>

                        <li className="flex justify-between py-1 border-b border-gray-300 last:border-none">
                            <span>Direction :</span>
                            <span>{details.direction}</span>
                        </li>

                        <li className="flex justify-between py-1 border-b border-gray-300 last:border-none">
                            <span>Category :</span>
                            <span>{details.category}</span>
                        </li>

                        <li className="flex justify-between font-bold text-green-700 mt-2">
                            <span>Total Estimated Cost</span>
                            <span>₹{totalCost.toFixed(2)}</span>
                        </li>
                    </ul>
                </div>

                {/* Title */}
                <h2 className="text-center text-2xl font-semibold mb-6">
                    Your Estimated Cost
                </h2>

                {/* Total Cost */}
                <p className="text-center text-lg font-medium mb-4">
                    {" "}
                    <span className="line-through text-red-500 font-semibold">₹{(totalCost / 0.7).toFixed(2)}</span>
                    <span className="text-green-700 font-semibold ml-2">
                        ₹{totalCost.toFixed(2)}
                    </span>
                    <span className="text-sm text-red-500 ml-2">(30% off)</span>
                </p>

                {/* Claim Offer Button */}
                <div className="text-center mb-6">
                    <Link
                        to="https://b2c.designelementary.in"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="bg-green-700 text-white py-3 px-6 rounded-lg hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                            Claim Offer Now!
                        </button>
                    </Link>
                </div>

                {/* Banner */}
                <div className="hidden md:block">
                    <Banner />
                </div>
            </div>
        </div>
    );
};

export default FinalCostModal;
